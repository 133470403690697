import * as React from 'react'
import { ICard, friendlyUrl } from '../models'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import AutoFitImage from 'react-image-autofit-frame'
import Rating from 'react-rating'
import './Card.css'

import wikiLogo from './wiki.png'

interface IProps {
  card: ICard
  parentPageid: string
}

const MAX_SUMMARY = 100

@observer
export default class Card extends React.Component<IProps> {

  get summary() {
    if (this.props.card.summary.length <= MAX_SUMMARY) {
      return this.props.card.summary
    }

    return this.props.card.summary.substr(0, 100) + '...'
  }

  render() {
    const { card } = this.props

    return (
      <div className="p-2 card">

        {
          this.props.card.loading ? (
            <div className="card-body">
              <div className="avatar-placeholder placeholder"></div>
              <div className="text-placeholder placeholder w-75"></div>
              <div className="text-placeholder placeholder w-50"></div>
              <div className="text-placeholder placeholder w-75"></div>
              <div className="text-placeholder placeholder w-100"></div>
              <div className="text-placeholder placeholder w-100"></div>
            </div>
          ) : (
              <div>
                <Link to={{
                  pathname: `/decks/${card.pageid}/${friendlyUrl(card.title)}`,
                  search: this.props.parentPageid ? `back=${this.props.parentPageid}` : undefined
                }}>
                  <div className="d-flex align-items-center mb-2">
                    <img src={wikiLogo} height="20px" width="20px" alt={card.title} />
                    <span className="ml-2 cardTitle">{card.title}</span>
                  </div>
                  <AutoFitImage imgSrc={card.imageUrl} frameWidth="310px" frameHeight="240px"
                  />
                </Link>
                <div className="card-body">
                  <Link to={{
                    pathname: `/decks/${card.pageid}/${friendlyUrl(card.title)}`,
                    search: this.props.parentPageid ? `back=${this.props.parentPageid}` : undefined
                  }}>
                    <p className="card-text card-text-limited">{this.summary}</p>
                  </Link>
                  <div className="card-text">
                    <small className="text-muted position-absolute author"
                    >By ZenBerg</small>

                    <div className="star position-absolute">
                      <Rating
                        emptySymbol="fa fa-star-o fa-2x"
                        fullSymbol="fa fa-star fa-2x"
                      />
                    </div>
                  </div>


                </div>
              </div>
            )
        }


      </div>
    )
  }
}
