import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import Home from './pages/Home';
import DeckDetails from './pages/DeckDetails';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/decks/:id/:slug">
          <DeckDetails />
        </Route>
        <Route path="/decks/:id">
          <DeckDetails />
        </Route>
        <Route path="/">
          <Home />
        </Route>

      </Switch>
    </Router>
  );
}

export default App;
