import React, { FormEvent } from 'react';
import './Home.css';
import { observable } from 'mobx';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Masonry from 'react-masonry-css'

import { search, ICard } from '../models'
import Card from '../components/Card';
import CardNew from '../components/CardNew';

import { observer } from 'mobx-react';

interface Props extends RouteComponentProps<any> {

}

@observer
class Home extends React.Component<Props> {

  @observable loading = false
  @observable query = ''
  @observable queryInSearch = ''
  @observable cards = observable.array<ICard>()

  constructor(props: Props) {
    super(props)

    const searchParams = new URLSearchParams(props.location.search)
    this.query = searchParams.get('query') || ''

    this.fetch()
  }

  async fetch() {
    if (!this.query.trim()) {
      this.queryInSearch = ''
      this.cards.replace([])
      return
    }

    this.loading = true
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: "?" + new URLSearchParams({ query: this.query }).toString()
    })
    this.queryInSearch = this.query
    await search(this.query, this.cards)
    this.loading = false
  }

  onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault()
    this.fetch()
  }

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget) return

    this.query = e.currentTarget.value;
  }

  render() {

    return (
      <div className="home-body container d-flex flex-column justify-content-center align-items-center">
        <div className="row"><div className="display-4 m-5">Wiki Decks</div></div>
        <form className="row" onSubmit={this.onSubmit}>
          <div className="col-12 col-sm-8 mt-3">
            <input autoFocus={true} className="form-control" type="text" aria-label="Search" name="query" value={this.query} onChange={this.onChange} />
          </div>
          <div className="col-12 col-sm-4 mt-3 align-self-center">
            <button type="submit" className="btn btn-primary"><i className="fa fa-search" aria-hidden="true" />Search</button>
          </div>
        </form>

        <br />

        {
          this.loading ? (
            <div className="row d-flex justify-content-center">
              <h3>Searching...</h3>
            </div>
          ) : undefined
        }

        {
          !this.loading && this.cards.length > 0 ? (
            <div className="row d-flex justify-content-center">
              <h3>Seach result - {this.cards.length} card(s)</h3>
            </div>
          ) : undefined
        }

        {
          !this.loading ? (
            <div className="row">
              <Masonry
                breakpointCols={3}
                className="my-masonry-grid"
                columnClassName="">
                {
                  this.cards.map((card) => (
                    <Card key={card.key} card={card} parentPageid="" />
                  ))
                }
              </Masonry>

            </div>
          ) : undefined
        }

        {
          this.queryInSearch && !this.loading && this.cards.length === 0 ? (
            <div className="row">
              <CardNew />
            </div>
          ) : undefined
        }


      </div >
    )
  }
}

export default withRouter(Home)

