import React from 'react';
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import Masonry from 'react-masonry-css'
import AutoFitImage from 'react-image-autofit-frame'

import styles from './DeckDetails.module.scss'
import Card from '../components/Card'
import { ICardDetails, ICard, fetchDetails } from '../models';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CancellablePromise } from 'mobx/lib/api/flow';
import Rating from 'react-rating';

import wikiLogo from '../components/wiki.png'

interface Props extends RouteComponentProps<any> {

}

const SMALL_CARD_HEIGHT = 490
const SMALL_CARD_MARGIN = 0

@observer
class DeckDetails extends React.Component<Props> {

  @observable details: ICardDetails = {
    loading: true,

    imageUrl: '',
    title: '',
    wikiUrl: '',
    contentHtml: '',
  }

  @observable relatedCards = observable.array<ICard>()

  id = ''

  promise: CancellablePromise<any> | null = null

  @observable uiStats = {
    mainCardHeight: 1016 // excluding margins
  }

  private inputSearch: React.RefObject<HTMLInputElement>;
  private cardMain: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props)

    this.inputSearch = React.createRef();
    this.cardMain = React.createRef();
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    // const searchResp = await wiki().find('star war')
    // console.log('searchResp', searchResp)

    const id = this.props.match.params.id
    this.id = id

    this.details.loading = true
    this.promise = fetchDetails(id, this.details, this.relatedCards, this.uiStats)
  }

  componentDidUpdate() {
    console.log('componentDidUpdate')
    const height = this.cardMain.current!.offsetHeight
    console.log('height', height)
    this.uiStats.mainCardHeight = height + 1
  }

  async componentWillReceiveProps(nextProps: Props) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      window.scrollTo(0, 0)
      this.details.loading = true
      this.relatedCards.replace([])

      if (this.promise) {
        try {
          this.promise.cancel()
        } catch (err) {
          console.error(err)
        }
      }

      this.promise = fetchDetails(nextProps.match.params.id, this.details, this.relatedCards, this.uiStats)
    }
  }

  get itemsPerCol(): { count: number, splitCase: boolean } {
    // na * a + (na - 1) * m = this.uiStats.mainCardHeight
    // na * a + na*m - m = this.uiStats.mainCardHeight
    // na * a + na*m = this.uiStats.mainCardHeight + m
    // na * (a + m) = this.uiStats.mainCardHeight + m
    // na = (this.uiStats.mainCardHeight + m) / (a + m)
    const expectedCols = Math.floor((this.uiStats.mainCardHeight + SMALL_CARD_MARGIN) / (SMALL_CARD_HEIGHT + SMALL_CARD_MARGIN))

    const colHeight = this.relatedCards.length * SMALL_CARD_HEIGHT + (this.relatedCards.length - 1) * SMALL_CARD_MARGIN

    // console.log('expectedCols', expectedCols)
    // console.log('this.uiStats.mainCardHeight', this.uiStats.mainCardHeight)
    // console.log('colHeight * 2', colHeight * 2)

    if (colHeight < this.uiStats.mainCardHeight * 2) {
      // console.log('/////22222', expectedCols / 2)
      return {
        count: Math.floor(this.relatedCards.length / 2),
        splitCase: true,
      }
    }

    return {
      count: expectedCols,
      splitCase: false,
    }
  }

  get leftCards(): ICard[] {
    return this.relatedCards.slice(0, this.itemsPerCol.count * 2).filter((_, i) => i % 2 === 0)
  }

  get rightCards(): ICard[] {
    return this.relatedCards.slice(0, this.itemsPerCol.count * 2).filter((_, i) => i % 2 !== 0)
  }

  get otherRelatedCards(): ICard[] {
    return this.relatedCards.slice(this.itemsPerCol.count * 2)
  }

  render() {
    return (
      <>
        <div style={{
          paddingTop: "12px",
          width: '1384px',
          margin: '0 auto',
          display: 'flex',
        }}>
          <button type="button" className="btn btn-light" onClick={() => {
            this.props.history.goBack()
          }}>Back</button>

          <form className="row" style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }} onSubmit={(e) => {
            e.preventDefault()

            const query = this.inputSearch.current ? this.inputSearch.current.value : ''

            this.props.history.push({
              pathname: '/',
              search: "?" + new URLSearchParams({ query }).toString()
            })
          }}>
            <div className="col-4 col-sm-4">
              <input autoFocus={true} className="form-control" type="text" aria-label="Search" name="query" ref={this.inputSearch} />
            </div>
            <div className="align-self-center">
              <button type="submit" className="btn btn-primary"><i className="fa fa-search" aria-hidden="true" />Search</button>
            </div>
          </form>
        </div>

        <div className={styles.container} style={{
          paddingTop: "12px"
        }}>

          <div className={styles.leftColumn + "row"}>
            {
              this.leftCards.map((card) => (
                <div key={card.key} className={styles.card}>
                  <Card card={card} parentPageid={this.id} />
                </div>
              ))
            }
          </div>
          <div ref={this.cardMain} className={styles.mainColumn + "row card"} style={{ width: '680px', minHeight: '1000px', padding: '8px', height: '100%' }}>
            {
              !this.details.loading ? (
                <Masonry
                  breakpointCols={1}
                  columnClassName={``}
                  className={``}
                >
                  <div className="">
                    <div className="d-flex align-items-center mb-2">
                      <img src={wikiLogo} height="20px" width="20px" alt={this.details.title} />
                      <span className="ml-2" style={{ fontWeight: 'bold' }}>{this.details.title}</span>
                    </div>
                    <AutoFitImage imgSrc={this.details.imageUrl} frameWidth="662px" frameHeight="470px"
                    />
                    <div className="card-body" style={{
                      height: 'inherit',
                      marginBottom: '63px',
                    }}>
                      <p className="card-text" style={{ overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: this.details.contentHtml }}>
                        {/* <iframe className="iframe" src={this.details.wikiUrl} /> */}
                      </p>
                      <div className="card-text">
                        <small className="text-muted position-absolute author"
                        >By ZenBerg</small>

                        <div className="star position-absolute">
                          <Rating
                            emptySymbol="fa fa-star-o fa-2x"
                            fullSymbol="fa fa-star fa-2x"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Masonry>
              ) : (
                  <div className="row d-flex flex-column justify-content-center align-items-center p-3" style={{ flex: 1 }}>
                    <div className="card-body" style={{ width: '100%', height: '100%' }}>
                      <div className="avatar-placeholder placeholder" style={{ width: '100%', height: '400px' }}></div>
                      <div className="text-placeholder placeholder w-75"></div>
                      <div className="text-placeholder placeholder w-50"></div>
                      <div className="text-placeholder placeholder w-75"></div>
                      <div className="text-placeholder placeholder w-100"></div>
                      <div className="text-placeholder placeholder w-100"></div>
                    </div>
                  </div>
                )
            }
          </div>
          <div className={styles.rightColumn + "row"}>
            {
              this.rightCards.map((card) => (
                <div key={card.key} className={styles.card}>
                  <Card card={card} parentPageid={this.id} />
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.sectionCards} style={{ width: '1380px' }}>
            <Masonry
              breakpointCols={4}
              className="row"
              columnClassName="">
              {
                this.otherRelatedCards.map((card) => (
                  <Card key={card.key} card={card} parentPageid={this.id} />
                ))
              }
            </Masonry>
          </div>
        </div>
      </>

    )
  }
}

export default withRouter(DeckDetails)
