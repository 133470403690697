import * as React from 'react'
import { observer } from 'mobx-react'

const img = require('./placeholder.png')
interface IProps {
}

@observer
export default class Card extends React.Component<IProps> {

  render() {
    return (
      <div className="p-2 card" style={{
        // minWidth: 300,
      }}>

        <div>
          <img src={img} className="card-img-top" alt="..." />
          <div className="card-body">
            <h4 className="card-title">Make Deck</h4>
          </div>
        </div>
      </div>
    )
  }
}
