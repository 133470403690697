import wiki from "wikijs";
import { runInAction, IObservableArray, flow } from "mobx";
export type ICard = {
  loading: boolean;

  key: string;

  pageid: string;
  imageUrl: string;
  title: string;
  wikiUrl: string;

  summary: string;
};

export type ICardDetails = {
  loading: boolean;

  imageUrl: string;
  title: string;
  wikiUrl: string;
  contentHtml: string;
};

export async function fetchCard(term: string): Promise<ICard> {
  const findResp = await wiki().find(term);

  const pageid = (findResp as any).raw.pageid;

  const resp = await wiki().findById(pageid);
  console.log("resp", resp);

  const title = (findResp as any).raw.title || term;

  const summary = await resp.summary();

  let imageUrl = "";
  try {
    imageUrl = await resp.mainImage();
    console.log("imageUrl", imageUrl);
  } catch (err) {
    const images = await resp.rawImages();
    console.log("raw images", images);
    if (images.length > 0) {
      const img = images.find((img) => img.imagerepository === "shared");
      if (img && img.imageinfo.length > 0) {
        imageUrl = (img.imageinfo[0] as any).url;
      }
    }
  }

  const details: ICard = {
    loading: false,

    key: term,

    pageid,
    title: title || term,
    wikiUrl: (await resp.url()).toString(),
    imageUrl,

    summary,
  };

  return details;
}

export async function search(
  searchTerm: string,
  out: IObservableArray<ICard>
): Promise<ICard[]> {
  const searchResp = await wiki().search(searchTerm, 10);

  const cards = searchResp.results.map(
    (term): ICard => ({
      loading: true,

      key: term,

      pageid: "",
      imageUrl: "",
      title: "",
      wikiUrl: "",

      summary: "",
    })
  );

  out.replace(cards);

  setTimeout(async () => {
    try {
      let i = 0;
      for (const card of cards) {
        const cardData = await fetchCard(card.key);
        const j = i;

        runInAction(() => {
          out[j].pageid = cardData.pageid;
          out[j].imageUrl = cardData.imageUrl;
          out[j].title = cardData.title;
          out[j].wikiUrl = cardData.wikiUrl;
          out[j].summary = cardData.summary;
          out[j].loading = false;
        });

        i++;
      }
    } catch (err) {
      console.error(err);
    }
  }, 0);

  return cards;
}

export const fetchDetails = flow(function * (
  pageid: string,
  details: ICardDetails,
  relatedCards: IObservableArray<ICard>,
  uiStats: {
    mainCardHeight: number
  }
) {
  const resp = yield wiki().findById(pageid);
  console.log("resp", resp);

  let imageUrl = "";
  try {
    imageUrl = yield resp.mainImage();
  } catch (err) {
    const images = yield resp.rawImages();
    if (images.length > 0) {
      const img = images.find((img) => img.imagerepository === "shared");
      if (img && img.imageinfo.length > 0) {
        imageUrl = (img.imageinfo[0] as any).url;
      }
    }
  }

  const contentHtml = yield resp.html()

  runInAction(() => {
    details.title = (resp as any).raw.title;
    details.wikiUrl = (resp as any).raw.fullurl;
    details.imageUrl = imageUrl;
    details.contentHtml = contentHtml;
    details.loading = false;
    console.log('update main card')
  })

  const linksResp: any = yield resp.links(true);
  console.log("linksResp", linksResp);
  const terms = Array.isArray(linksResp) ? linksResp : linksResp.results

  let termIndex: { index: number; term: string }[] = terms.map(
    (term): { index: number; term: string } => {
      const index = contentHtml.indexOf(term);

      return {
        index,
        term,
      };
    }
  );
  termIndex = termIndex
    .filter((ti) => ti.index !== -1)
    .sort(function (a, b) {
      return a.index - b.index;
    });
  console.log("termIndex", termIndex);

  const cards = termIndex.slice(0, 100).map(
    (ind): ICard => ({
      loading: true,

      key: ind.term,

      pageid: "",
      imageUrl: "",
      title: "",
      wikiUrl: "",

      summary: "",
    })
  );
  relatedCards.replace(cards);

  setTimeout(async () => {
    try {
      let i = 0;
      for (const card of cards) {
        const cardData = await fetchCard(card.key);
        const j = i;

        runInAction(() => {
          relatedCards[j].pageid = cardData.pageid;
          relatedCards[j].imageUrl = cardData.imageUrl;
          relatedCards[j].title = cardData.title;
          relatedCards[j].wikiUrl = cardData.wikiUrl;
          relatedCards[j].summary = cardData.summary;
          relatedCards[j].loading = false;
        });

        i++;
      }
    } catch (err) {
      console.error(err);
    }
  }, 0);
})

export function friendlyUrl(str, max = 32) {
  if (max === undefined) max = 32;
  var a_chars = [
    ["a",/[áàâãªÁÀÂÃ]/g],
    ["e",/[éèêÉÈÊ]/g],
    ["i",/[íìîÍÌÎ]/g],
    ["o",/[òóôõºÓÒÔÕ]/g],
    ["u",/[úùûÚÙÛ]/g],
    ["c",/[çÇ]/g],
    ["n",/[Ññ]/g]
  ];
  // Replace vowel with accent without them
  for(var i=0;i<a_chars.length;i++)
    str = str.replace(a_chars[i][1],a_chars[i][0]);
  // first replace whitespace by -, second remove repeated - by just one, third turn in low case the chars,
  // fourth delete all chars which are not between a-z or 0-9, fifth trim the string and
  // the last step truncate the string to 32 chars
  return str.replace(/\s+/g,'-').toLowerCase().replace(/[^a-z0-9\-]/g, '').replace(/\-{2,}/g,'-').replace(/(^\s*)|(\s*$)/g, '').substr(0,max);
}